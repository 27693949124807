const authZConfig = {
    defaultLoginEnabled: true,
    selfRegistrationEnabled: true,
    providerLoginEnabled: true,
    facebookLoginEnabled: false,
    googleLoginEnabled: true,
    microsoftLoginEnabled: false,
    yahooLoginEnabled: false,
    gatewayBaseUrl: "WW_GATEWAY_BASE_URL",
    loginSuccessRoute: '/home',
    localStorageUser: 'loggedInUser',
    localStorageAppUserId: 'appUserId'
};

export default authZConfig;
